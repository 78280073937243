.container{
    display: flex;
    flex-direction: column;
    width: 20rem;
    height: 40rem;
    padding: 2rem;
    border-radius: 0.5rem;
    margin: 1rem;
    color: var(--textCP);
    background-color: var(--primaryM);
    box-shadow: var(--shadow3);
    animation: loading 0.8s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes loading {
    0%{opacity: 1;}
    100%{opacity: 0.5;}
}

@media (max-width: 768px) {
    .container{
        width: 95%;
    }
}