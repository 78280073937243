.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.header svg {
    font-size: 1rem;
    color: var(--primaryM);
}

.subtitle {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 1rem;
    color: var(--primaryM);
}

.title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    max-width: 40rem;
    margin-top: 1rem;
    color: var(--textM);
}

.txt {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    max-width: 40rem;
    margin-top: 1rem;
    color: var(--textM);
}

/* DARK THEME */
.title-dt {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    max-width: 40rem;
    margin-top: 1rem;
    color: var(--textCSe);
}

.txt-dt {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    max-width: 40rem;
    margin-top: 1rem;
    color: var(--textCSe);
    opacity: 0.5;
}

@media (max-width: 768px) {

    .title, .title-dt {
        font-size: 2rem;
    }

}