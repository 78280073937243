.container{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    color: var(--textCSe);
    background: linear-gradient(to bottom, var(--secondaryD) 0%, var(--secondaryM) 20%);
    overflow: hidden;
}

.bg-tr{
    position: absolute;
    top: 0;
    right: 0;
    width: 40rem;
}

.left-side{
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 4rem;
}

.right-side{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* LEFT SIDE */
.txt-100{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.txt-100 svg{
    font-size: 1.3rem;
    color: var(--primaryM);
    margin-right: 0.5rem;
}
.txt-100 span{
    font-size: 1.3rem;
    font-weight: 400;
}

.title{
    font-size: 4rem;
    font-weight: 800;
    margin: 1rem 0;
}

.subtitle{
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--textCSe);
    opacity: 0.5;
}

.left-side a{
    position: relative;
}

.left-side a::after{
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: pulse 2s ease-out infinite;
    opacity: 0;
    border-radius: 2rem;
    background: rgba(255, 255, 255, 0.5);
}

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        transform: scale(1.4);
        opacity: 0;
    }
    
}

/* RIGHT SIDE */

.img-foguete{
    width: 90%;
    height: auto;
}

@media (max-width: 768px){
    
    .left-side{
        width: 100%;
        padding: 0 2rem;
        align-items: center;
    }

    .right-side{
        display: none;
    }
    
    .title{
        font-size: 3rem;
        text-align: center;
    }
    
    .subtitle{
        text-align: center;
    }
}