.container{
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 4rem;
    margin-bottom: -1px;
    color: var(--textCSe);
    background-color: var(--secondaryM);
}

.logo{
    width: 11rem;
    height: auto;
}

.nav-list{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-item{
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0 1rem;
    transition: all 0.3s;
}
.nav-item:hover, .nav-item:active{
    color: var(--primaryM);
}

.icon-menu{
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    transform: rotateZ(0deg);
    transition: all 0.3s ease-in-out;
}


@media (max-width: 768px) {
    .container{
        padding: 1rem 2rem;
    }
    
    .icon-menu{
        display: block;
    }

    .nav-list{
        position: absolute;
        top: 1rem;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        transform: translateY(8vh);
        height: none;
        max-height: 300px;
        width: 100%;
        padding: 0;
        background-color: var(--secondaryM);
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    .nav-item{
        font-size: 1.1rem;
        font-weight: 500;
        width: 100%;
        padding: 1rem 2rem;
        border-bottom: 2px solid var(--textM);
        margin: 0;
        transition: all 0.3s;
    }
    .nav-item:last-child{
        border-bottom: 2px solid transparent;
    }
}