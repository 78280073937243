@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {

  /* ALTERAR */
  --bk1: #ffffff; /* Cor de BG principal */
  --bk2: #f3faff; /* Cor de BG secundária, um pouco mais escura ou mais clara que a principal */
  --bk1T: #ffffffbf; /* Cor de BG principal transparente | COR + bf */
  --bk2T: #F6F3FFbf; /* Cor de BG secundária transparente | COR + bf */
  --textM: #737491; /* Cor de texto que contraste com o os 2 BG */
  --textL: #737491b3; /* Cor de texto light | COR + b3 */
  --textD: #73749180; /* Cor de texto disabled | COR + 80 */
  --primaryM: #00BAEE; /* Cor Primária */
  --primaryL: #06C8FF; /* Cor Primária mais clara */
  --primaryD: #0080A4; /* Cor Primária mais escura */
  --primaryT: #00BAEEbf; /* Cor Primária transparente | COR + bf */
  --textCP: #ffffff; /* Cor de texto que contraste a Cor Primária */
  --secondaryM: #212C96; /* Cor Secundária */
  --secondaryL: #3D47A4; /* Cor Secundária mais clara */ 
  --secondaryD: #151E78; /* Cor Secundária mais escura */ 
  --secondaryT: #212C96bf; /* Cor Secundária transparente | COR + bf */ 
  --textCSe: #ffffff; /* Cor de texto que contraste a Cor Secundária */
  
  /*NÃO ALTERAR*/
  --btnWpp: #26CC64;
  --divider: #ffffff1f;
  --shadow: 5px 5px 10px #2424242f;
  --shadow2: 0px 15px 20px -15px #24242459;
  --shadow3: 5px 5px 10px #2424240e;
}

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid red; */
}

*:focus {
  outline: 0;
}
::selection {
  color: var(--textCP);
  background-color: var(--primaryT);
}

html,
body,
#root {
  scroll-behavior: smooth;
  font-size: 16px;
  height: 100%;
  width: 100%;
}

@media (max-width:1200px) {

  html,
  body,
  #root {
    font-size: 14px;
    height: 100%;
    width: 100%;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

li {
  list-style: none;
}

button {
  border-width: none;
  cursor: pointer;
}

/* SCROLLBAR*/
::-webkit-scrollbar {
  width: 0.3rem;
}

::-webkit-scrollbar-track {
  background: var(--secondaryM);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: var(--primaryM);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primaryM);
}