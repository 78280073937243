.container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.container::before{
    content: '';
    z-index: -1;
    position: absolute;
    top: -1px;
    width: 100%;
    height: 10rem;
    background-color: var(--secondaryM);
}

.lista-planos{
    width: 100%;
    padding: 0rem 4rem;
}

@media (max-width: 768px) {
    .lista-planos{
        padding: 0rem 2rem;
    }
}