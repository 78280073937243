.container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 12rem 4rem 1rem 4rem;
    background-color: var(--bk2);
}

.container::before{
    content: '';
    position: absolute;
    top: -1px;
    width: 100%;
    height: 6rem;
    background-color: var(--bk1);
    border-radius: 0 0 10rem 10rem;
}

.perguntas-faq{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .container {
        padding: 12rem 2rem 6rem 2rem;
    }
}