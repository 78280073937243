.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 6rem 4rem 6rem 4rem;
    color: var(--textCSe);
    background-color: var(--secondaryM);
}

.container-app{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 2rem;
}

.app-column{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.app-iphone{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
    width: 80%;
}
.app-iphone::before{
    content: 'Baixar App';
    z-index: 2;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    height: 0;
    width: 94%;
    border-radius: 3.7rem;
    opacity: 0;
    color: var(--textCP);
    background-color: var(--primaryM);
    transition: all 0.5s;
}
.app-iphone:hover::before{
    opacity: 0.9;
    height: 100%;
    width: 94%;
}
.app-iphone::after{
    content: '';
    background: url('../../../assets/print_app.png') no-repeat center;
    background-size: contain;
    position: absolute;
    width: 90%;
    height: 95%;
}

.app-iphone-img{
    z-index: 1;
    width: 100%;
}

.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem 1rem;
    border: 2px solid var(--divider);
    border-radius: 0.2rem;
    margin: 1rem;
    overflow: hidden;
    transition: all 0.3s;
}
.card:hover{
    border: 2px solid var(--primaryM);
}

.icon-card{
    font-size: 3rem;
    color: var(--primaryM);
}

.title-card{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1rem;
    color: var(--textCSe);
}

.txt-card{
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 1rem;
    color: var(--textCSe);
    opacity: 0.5;
}

@media (max-width: 768px) {
    .container{
        padding: 6rem 2rem 6rem 2rem;
    }
    
    .container-app{
        flex-direction: column;
    }

    .app-iphone{
        margin-bottom: 5rem;
    }

    .app-iphone::before{
        content: 'Baixar App';
        position: absolute;
        bottom: -4.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
        height: 3rem;
        width: 95%;
        border-radius: 3.7rem;
        opacity: 1;
        color: var(--textCP);
        background-color: var(--primaryM);
        transition: all 0.5s;
    }
    .app-iphone:hover::before{
        opacity: 1;
        height: 3rem;
        width: 95%;
    }
}