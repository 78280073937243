.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 6rem 4rem 0rem 4rem;
    color: var(--textM);
}

.left-side {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* LEFT SIDE */

.subtitle {
    display: flex;
    align-items: center;
    color: var(--primaryM);
}

.subtitle svg {
    font-size: 1.5rem;
    margin-right: 1rem;
}

.subtitle span {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
}

.title {
    text-align: justify;
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: 1rem;
}

.text {
    text-align: justify;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;
}


@media (max-width: 768px) {
    .container {
        flex-direction: column;
        padding: 6rem 2rem 0rem 2rem;
    }

    .right-side {
        margin-top: 2rem;
    }
}