.link-whats{
    display: flex;
    z-index: 3;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: fit-content;
    height: fit-content;
    padding: 0.5rem;
    border-radius: 50%;
    font-size: 3rem;
    color: var(--textCP);
    background-color: var(--btnWpp);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}